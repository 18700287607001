var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-bg pt-4 pb-4"
  }, [_c('div', {
    staticClass: "account-pages"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-6 text-right"
  }, [_c('div', {
    staticClass: "login-left-side pt-3"
  }, [_c('h2', {
    style: {
      color: '#0b4990'
    }
  }, [_vm._v(" One App for all your needs! ")])])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('h3', {
    staticClass: "mt-4 pb-4",
    style: {
      color: '#0b4990'
    },
    attrs: {
      "id": "login-shopping-heading"
    }
  }, [_vm._v(" Enjoy your Shopping with us! ")]), _c('div', {
    staticClass: "card mt-4 rounded-lg"
  }, [_c('div', {
    staticClass: "card-header pt-2 pb-2 text-center bg-orange rounded-top"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3"
  }, [_c('b-img', {
    attrs: {
      "src": "/img/login/logo_icon.png",
      "width": "55px",
      "height": "55px",
      "fluid": ""
    }
  })], 1), _c('div', {
    staticClass: "col-lg-7"
  }, [_c('b-img', {
    attrs: {
      "src": "/img/login/e-পরিষেবা_text logo.png",
      "width": "150px",
      "height": "auto"
    }
  })], 1)])]), _c('div', {
    staticClass: "card-body p-4"
  }, [!_vm.otpKey ? _c('div', {
    staticClass: "form-group"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Phone Number"
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }), !_vm.isPhoneValid ? _c('p', {
    staticClass: "is-valid-phone"
  }, [_vm._v(" Enter a valid phone number ")]) : _vm._e()], 1) : _vm._e(), _vm.otpKey ? _c('div', {
    staticClass: "row justify-content-md-center"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    style: {
      color: _vm.otpMsg.key
    }
  }, [_vm._v(" " + _vm._s(_vm.otpMsg.value) + " ")]), _c('form', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "action": ""
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.otpInput1,
      expression: "otpInput1"
    }],
    staticClass: "otp border",
    attrs: {
      "type": "text",
      "maxlength": "1"
    },
    domProps: {
      "value": _vm.otpInput1
    },
    on: {
      "keyup": function keyup($event) {
        return $event.target.nextElementSibling.focus();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.otpInput1 = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.otpInput2,
      expression: "otpInput2"
    }],
    staticClass: "otp border",
    attrs: {
      "type": "text",
      "maxlength": "1"
    },
    domProps: {
      "value": _vm.otpInput2
    },
    on: {
      "keyup": function keyup($event) {
        return $event.target.nextElementSibling.focus();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.otpInput2 = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.otpInput3,
      expression: "otpInput3"
    }],
    staticClass: "otp border",
    attrs: {
      "type": "text",
      "maxlength": "1"
    },
    domProps: {
      "value": _vm.otpInput3
    },
    on: {
      "keyup": function keyup($event) {
        return $event.target.nextElementSibling.focus();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.otpInput3 = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.otpInput4,
      expression: "otpInput4"
    }],
    staticClass: "otp border",
    attrs: {
      "type": "text",
      "maxlength": "1"
    },
    domProps: {
      "value": _vm.otpInput4
    },
    on: {
      "keyup": function keyup($event) {
        return $event.target.nextElementSibling.focus();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.otpInput4 = $event.target.value;
      }
    }
  })])])]) : _vm._e(), _vm.otpKey && !_vm.isLoading ? _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" Not Recived Your Code? "), _vm.countDownObj.countDownVar > 0 ? _c('b', [_c('a', {
    staticClass: "modal-title-otp-timer",
    attrs: {
      "id": "resendCode"
    }
  }, [_vm._v("Resend Code in " + _vm._s(_vm.countDownObj.msg) + " ")])]) : _c('b', {
    staticClass: "modal-title-otp"
  }, [_c('a', {
    attrs: {
      "id": "resendCode"
    },
    on: {
      "click": function click() {
        return _this.login();
      }
    }
  }, [_vm._v("Resend Code Otp ")])])]) : _vm._e(), _c('div', {
    staticClass: "pt-4 form-group text-center"
  }, [!_vm.isLoading ? [!_vm.otpKey ? _c('b-button', {
    staticClass: "primary-btn",
    attrs: {
      "disabled": !_vm.isPhoneValid,
      "size": "lg"
    },
    on: {
      "click": function click() {
        return _this.login();
      }
    }
  }, [_vm._v("Login with OTP")]) : _vm._e(), _vm.otpKey ? _c('b-button', {
    staticClass: "primary-btn",
    attrs: {
      "disabled": !_vm.isPhoneValid,
      "size": "lg"
    },
    on: {
      "click": function click() {
        return _this.loginOtp();
      }
    }
  }, [_vm._v("Login with OTP")]) : _vm._e()] : _vm._e(), _vm.isLoading ? _vm._l(3, function (i) {
    return _c('b-spinner', {
      key: i,
      staticClass: "mr-1",
      attrs: {
        "small": "",
        "label": "Small Spinner",
        "type": "grow"
      }
    });
  }) : _vm._e()], 2), !_vm.otpKey ? [_vm._m(0), _c('div', {
    staticClass: "form-group text-center"
  }, [_c('button', {
    staticClass: "mr-3 fa-facebook",
    attrs: {
      "size": ""
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "facebook",
      "aria-label": "Help"
    }
  }), _vm._v(" Facebook ")], 1), _c('button', {
    staticClass: "ml-3 fa-google",
    attrs: {
      "size": ""
    },
    on: {
      "click": _vm.googleLogin
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "google",
      "aria-label": "Help"
    }
  }), _vm._v(" Google ")], 1)])] : _vm._e()], 2)])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center pt-3"
  }, [_c('p', [_vm._v("Login Using")])]);
}]

export { render, staticRenderFns }