<template>
  <div class="login-bg pt-4 pb-4">
    <div class="account-pages">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-right">
            <div class="login-left-side pt-3">
              <h2 v-bind:style="{ color: '#0b4990' }">
                One App for all your needs!
              </h2>
            </div>
          </div>
          <div class="col-lg-6">
            <h3
              class="mt-4 pb-4"
              id="login-shopping-heading"
              v-bind:style="{ color: '#0b4990' }"
            >
              Enjoy your Shopping with us!
            </h3>
            <div class="card mt-4 rounded-lg">
              <div
                class="card-header pt-2 pb-2 text-center bg-orange rounded-top"
              >
                <div class="row">
                  <div class="col-lg-3">
                    <b-img
                      src="/img/login/logo_icon.png"
                      width="55px"
                      height="55px"
                      fluid
                    >
                    </b-img>
                    <!-- <img src="/img/logo_icon.png"/> -->
                  </div>
                  <div class="col-lg-7">
                    <b-img
                      src="/img/login/e-পরিষেবা_text logo.png"
                      width="150px"
                      height="auto"
                    >
                    </b-img>
                    <!-- <img src="/img/e-পরিষেবা_text logo.png"/> -->
                  </div>
                </div>
                <!-- <span class="text-white font-weight-bold h2">E parisheva</span> -->
              </div>
              <div class="card-body p-4">
                <!-- <form @submit.prevent="onSubmit"> -->
                <div v-if="!otpKey" class="form-group">
                  <v-text-field
                    v-model="phone"
                    placeholder="Phone Number"
                  ></v-text-field>
                  <p v-if="!isPhoneValid" class="is-valid-phone">
                    Enter a valid phone number
                  </p>
                </div>
                <!-- <div class="form-group">
                  <v-text-field
                    v-model="password"
                    type="password"
                    placeholder="Enter Password"
                  ></v-text-field>
                </div> -->
                <!-- <div class="form-group">
                  <b-form-input
                    v-model="password"
                    placeholder="Enter Password"
                  ></b-form-input>
                </div>
                <div class="text-right pb-2">
                  <a href="#">Forgot Password</a>
                </div> -->
                <!-- ////////////// otp ///////////////////// -->
                <div v-if="otpKey" class="row justify-content-md-center">
                  <div class="text-center">
                    <p :style="{ color: otpMsg.key }">
                      {{ otpMsg.value }}
                    </p>
                    <form action="" class="mt-4 mb-4">
                      <input
                        v-model="otpInput1"
                        v-on:keyup="$event.target.nextElementSibling.focus()"
                        class="otp border"
                        type="text"
                        maxlength="1"
                      />
                      <input
                        v-model="otpInput2"
                        v-on:keyup="$event.target.nextElementSibling.focus()"
                        class="otp border"
                        type="text"
                        maxlength="1"
                      />
                      <input
                        v-model="otpInput3"
                        v-on:keyup="$event.target.nextElementSibling.focus()"
                        class="otp border"
                        type="text"
                        maxlength="1"
                      />
                      <input
                        v-model="otpInput4"
                        class="otp border"
                        v-on:keyup="$event.target.nextElementSibling.focus()"
                        type="text"
                        maxlength="1"
                      />
                    </form>
                  </div>
                </div>
                <!-- //////////////////////////////////////// -->
                <div v-if="otpKey && !isLoading" style="text-align: center">
                  Not Recived Your Code?
                  <b v-if="countDownObj.countDownVar > 0"
                    ><a id="resendCode" class="modal-title-otp-timer"
                      >Resend Code in {{ countDownObj.msg }}
                    </a>
                  </b>
                  <b v-else class="modal-title-otp"
                    ><a id="resendCode" @click="() => this.login()"
                      >Resend Code Otp
                    </a>
                  </b>
                </div>

                <div class="pt-4 form-group text-center">
                  <template v-if="!isLoading">
                    <b-button
                      v-if="!otpKey"
                      :disabled="!isPhoneValid"
                      class="primary-btn"
                      size="lg"
                      @click="() => this.login()"
                      >Login with OTP</b-button
                    >
                    <b-button
                      v-if="otpKey"
                      :disabled="!isPhoneValid"
                      class="primary-btn"
                      size="lg"
                      @click="() => this.loginOtp()"
                      >Login with OTP</b-button
                    >
                  </template>

                  <template v-if="isLoading">
                    <b-spinner
                      v-for="i in 3"
                      :key="i"
                      class="mr-1"
                      small
                      label="Small Spinner"
                      type="grow"
                    ></b-spinner>
                  </template>
                </div>

                <template v-if="!otpKey">
                  <div class="text-center pt-3">
                    <p>Login Using</p>
                  </div>
                  <div class="form-group text-center">
                    <button class="mr-3 fa-facebook" size="">
                      <b-icon icon="facebook" aria-label="Help"></b-icon>
                      Facebook
                    </button>
                    <button @click="googleLogin" class="ml-3 fa-google" size="">
                      <b-icon icon="google" aria-label="Help"></b-icon> Google
                    </button>
                    <!-- <GoogleLogin
                      :params="params"
                      :onSuccess="onSuccess"
                      :onFailure="onFailure"
                      style=""
                      class="fa-google"
                    >
                      <b-icon icon="google" aria-label="Help"></b-icon>
                      Google</GoogleLogin
                    > -->
                  </div>
                </template>
                <!-- <div class="text-center">
                  <p>
                    Already Have An Account?
                    <router-link :to="{ name: 'register' }"
                      >Create An Account
                    </router-link>
                  </p>
                </div> -->
              </div>
              <!-- end card-body -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import GoogleLogin from "vue-google-login";
import { socialLoginSignup } from "../auth.services";

import { mapState } from "vuex";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
export default {
  name: "login",
  components: {
    //  GoogleLogin,
  },
  computed: {
    ...mapState({
      cartData: (state) => state.checkout.cartData,
    }),
    isPhoneValid() {
      if (this.phone == "") return true;
      var isPhone = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      if (isPhone.test(this.phone)) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      phone: "",
      // password: "",
      otpMsg: { value: "", key: "" },
      otpKey: false,
      otpInput1: "",
      otpInput2: "",
      otpInput3: "",
      otpInput4: "",
      isLoading: false,
      countDownObj: {
        countDownVar: 0,
        msg: "",
      },
    };
  },

  methods: {
    timerFunc() {
      this.countDownObj.countDownVar = 60;
      this.countDownObj.msg = `00 : ${this.countDownObj.countDownVar}`;
      clearInterval(countDownIntervalFunc);
      const countDownIntervalFunc = setInterval(() => {
        this.countDownObj.countDownVar--;
        if (this.countDownObj.countDownVar < 10) {
          this.countDownObj.msg = `00 : 0${this.countDownObj.countDownVar}`;
        } else if (this.countDownObj.countDownVar > 10) {
          this.countDownObj.msg = `00 : ${this.countDownObj.countDownVar}`;
        }
        if (this.countDownObj.countDownVar < 0) {
          clearInterval(countDownIntervalFunc);
        }
      }, 1000);
    },
    googleLogin() {
      const x = new GoogleAuthProvider();
      console.log(x, "xxxxxx");
      signInWithPopup(getAuth(), x)
        .then(async (res) => {
          console.log("google res", res);
          try {
            const result = await socialLoginSignup({
              data: {
                accessToken: res._tokenResponse.oauthIdToken,
              },
              type: "GOOGLE",
            });
            console.log(result, "result");
            const userdata = {
              ...result.data.response.user,
              loginType: "social",
            };
            console.log(userdata, "userdata");
            if (result) {
              const token = await result.data.response.token;
              console.log(token, "token");
              localStorage.setItem("eparisheva:access_token", token);
              localStorage.setItem(
                "eparisheva:userInfo",
                JSON.stringify(userdata)
              );
              this.$store.dispatch("auth/loginToken", token);
              this.$store.dispatch("auth/setUserData", userdata);
              this.$store.commit(
                "address/setDeliveryAddress",
                userdata.addresses
              );
              setTimeout(() => {
                this.$router.push("/");
              }, 1500);
            }
          } catch (error) {
            console.log(error);
          }
        })
        .catch((err) => {
          console.log("google err", err);
        });
    },

    async loginOtp() {
      this.isLoading = true;
      const payload = {};
      payload.mobile = this.phone;
      payload.otp =
        this.otpInput1 + this.otpInput2 + this.otpInput3 + this.otpInput4;
      await this.$store
        .dispatch("auth/callLogin", payload)
        .then(async (result) => {
          const token = await result.data.response.token;
          localStorage.setItem("eparisheva:access_token", token);
          localStorage.setItem(
            "eparisheva:userInfo",
            JSON.stringify(result.data.response.user)
          );
          this.$store.dispatch("auth/loginToken", token);
          this.$store.dispatch("auth/setUserData", result.data.response.user);
          this.$store.commit(
            "address/setDeliveryAddress",
            result.data.response.user.addresses
          );
          setTimeout(() => {
            this.$router.push("/");
            this.isLoading = false;
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          this.otpMsg = { value: "Invalid OTP", key: "red" };
          this.otpInput1 =
            this.otpInput2 =
            this.otpInput3 =
            this.otpInput4 =
              "";
          this.isLoading = false;
        });
    },
    async onSuccess(googleUser) {
      const authDetails = googleUser.getAuthResponse();
      // const basicProfileDetail = googleUser.getBasicProfile();
      console.log("onSuccess", authDetails);
      try {
        const result = await socialLoginSignup({
          data: {
            accessToken: authDetails.id_token,
          },
          type: "GOOGLE",
        });
        const userdata = { ...result.data.response.user, loginType: "social" };
        if (result) {
          const token = await result.data.response.token;
          //const user = await result.data.response.user;
          localStorage.setItem("eparisheva:access_token", token);
          localStorage.setItem("eparisheva:userInfo", JSON.stringify(userdata));
          this.$store.dispatch("auth/loginToken", token);
          this.$store.dispatch("auth/setUserData", userdata);
        }
      } catch (error) {
        console.log(error);
      }
    },
    onFailure(error) {
      console.log(error);
    },
    async login() {
      this.isLoading = true;
      try {
        const payload = {};
        payload.mobile = this.phone;

        const result = await this.$store.dispatch("auth/callLogin", payload);
        if (result.response) {
          let msg = result.response.data.message;
          this.$swal({
            text: msg,
            type: "error",
          });
        }
        if (result.data.status == 1) {
          this.otpKey = true;
          this.otpMsg = { value: result.data.message, key: "green" };
          this.timerFunc();
        } else {
          this.otpMsg = { value: "can not send otp", key: "red" };
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.fa-facebook {
  border: 1px solid rgb(60, 90, 154);
  color: rgb(60, 90, 154);
  padding: 7px 15px;
  border-radius: 0.25rem;
}

.fa-facebook:hover {
  background: rgb(60, 90, 154);
  color: #fff !important;
}

.fa-google {
  border: 1px solid rgb(220, 74, 61);
  color: rgb(220, 74, 61);
  padding: 7px 15px;
  border-radius: 0.25rem;
}

.fa-google:hover {
  background: rgb(220, 74, 61);
  color: #fff !important;
}

.otp {
  margin-right: 10px;
}

form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
}

.login-bg {
  background-image: url("/img/login/login_bg_image.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
}

.bg-orange {
  background: #2a8750;
}
.modal-title-otp a:hover {
  text-decoration: underline !important;
}

.primary-btn {
  background: #2a8750;
  color: #fff;
  border-color: #2a8750;
  padding: 0.5rem 8rem;
}

.is-valid-phone {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 9px;
  color: red;
}

.border {
  border: 1px solid #000;
}

.modal-title-otp {
  color: #579a70;
  cursor: pointer;
}

.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  width: 65%;
}

@media (max-width: 768px) {
  .login-left-side.pt-3 {
    padding-top: 0 !important;
  }

  .login-left-side h2 {
    font-size: 20px;
    margin-bottom: 0;
    text-align: center;
  }

  img.login-logo-icon.img-fluid {
    width: 30px;
  }

  #login-shopping-heading {
    margin-top: 0px !important;
    font-size: 20px;
    text-align: center;
    padding-bottom: 0 !important;
  }

  .btn-primary {
    padding: 0.5rem 1.5rem;
  }

  img.mt-2.teka-login-logo {
    margin-top: 0 !important;
  }
}
</style>
